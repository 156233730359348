import { graphql } from 'gatsby';
import { HeadInner } from '../../../components/Head';
import DocumentsTemplate from '../../../template/DocumentsTemplate';
import { ServiceCode } from '../../../util/post';

export const Head: React.FC<{
  data: GatsbyTypes.DocumentVideoQueryQuery;
}> = (props) => {
  const title = props.data.contentfulVideo?.title;
  const slug = props.data.contentfulVideo?.slug;
  return (
    <HeadInner
      path={`/documents/${slug}`}
      title={title}
      description={props.data.contentfulVideo?.description?.description}
    />
  );
};

const DocumentsVideo: React.FC<{
  data: GatsbyTypes.DocumentVideoQueryQuery;
}> = (props) => {
  const document = props.data.contentfulVideo;
  const requiredList = [
    'company',
    'employees',
    'email',
    'lastname',
    'firstname',
    'department',
    'phone',
    'industry',
  ];

  if (!document) return null;
  return (
    <DocumentsTemplate
      type='seminar_archive'
      title={document.title}
      coverImage={document.coverImage}
      description={document.description}
      slug={document.slug}
      requiredList={requiredList}
      serviceCodes={
        document.serviceCodes ? (document.serviceCodes as ServiceCode[]) : []
      }
    />
  );
};

export const query = graphql`
  query DocumentVideoQuery($id: String) {
    contentfulVideo(id: { eq: $id }) {
      title
      slug
      coverImage {
        gatsbyImageData
        title
      }
      description {
        description
      }
      serviceCodes
    }
  }
`;

export default DocumentsVideo;
