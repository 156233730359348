import { css } from '@mui/material';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import {
  PostConversionInfo,
  ServiceCode,
  handlePostWithTrackingCV,
} from '../util/post';
import { ChangeEvent, FocusEvent, useState } from 'react';
import { Layout } from '../components/layout';
import { RegistrationLayout } from '../components/forms/RegistrationLayout';
import { colors } from '../theme/colors';
import { navigate } from 'gatsby';

const DEFAULT_DESCRIPTION_TEXT =
  '<p>法人支出の最適化を実現するpaild（ペイルド）に関するお役立ち資料やセミナー動画をご用意しています。<br />従来の法人カードとの比較や、機能や事例をご説明していますので、ぜひご覧ください。</p>';

const DocumentsTemplate: React.FC<{
  type: 'whitepaper' | 'seminar_archive';
  title: string | null;
  coverImage: {
    readonly gatsbyImageData: IGatsbyImageData | null;
    readonly title: string | null;
  } | null;
  description: {
    readonly description: string | null;
  } | null;
  slug: string | null | undefined;
  requiredList: string[];
  learningPoints?: readonly (string | null)[] | null;
  serviceCodes?: ServiceCode[];
}> = (props) => {
  const env = process.env.CONTEXT;
  const portalId = process.env.HUBSPOT_ID ? process.env.HUBSPOT_ID : '';
  const formId =
    env === 'dev' || env === 'stg'
      ? 'af37d51d-479e-466d-97e1-a8e44a33f75e'
      : 'dd7275ac-9009-4ad4-b28a-6786765fb7c6';

  const [form, setForm] = useState<PostConversionInfo>({
    email: '',
    lastname: '',
    firstname: '',
    company: '',
    employees: '',
    department: '',
    jobtitle: '',
    phone: '',
    industry: '',
    pageUri: location.href,
    registration: false,
    request_document: true,
    selected_document: props.slug || '',
    service_codes: props.serviceCodes || [],
  });

  const [agree, setAgree] = useState(false);

  const handleAgree = () => {
    setAgree(!agree);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm({
      ...form,
      phone: e.currentTarget.value.replace(/-/g, ''),
    });
  };

  const onPostButtonClick = async () => {
    try {
      await handlePostWithTrackingCV(form, portalId, formId, props.title || '');
      navigate(`/documents/${props.slug}/complete/`);
    } catch (e) {
      // todo
    }
  };

  const links = [
    { name: 'お役立ち資料', path: '/documents/' },
    {
      name: props.title || 'お役立ち資料ダウンロード',
      path: `/documents/${props.slug || ''}`,
    },
  ];

  return (
    <Layout
      header='simple'
      background='simple'
      footer='simple'
      isBottomMenu={false}
      links={links}
    >
      <RegistrationLayout
        form={form}
        onChange={handleChange}
        onCheckBoxChange={handleCheck}
        onSelectChange={handleSelectChange}
        onBlur={handlePhoneBlur}
        agree={agree}
        handleAgree={handleAgree}
        requiredList={props.requiredList}
        onPostButtonClick={onPostButtonClick}
      >
        <h2 css={RequestDocumentTitleStyle}>
          {props.type === 'whitepaper'
            ? '資料ダウンロード'
            : 'アーカイブ動画の申し込み'}
        </h2>

        {props.coverImage && (
          <GatsbyImage
            image={props.coverImage!.gatsbyImageData!}
            alt={props.coverImage!.title!}
          />
        )}
        {/* Contentfulで設定した文字列に対し<p></p>ごとに改行を入れておく */}
        <div
          css={RequestDocumentDescriptionStyle}
          dangerouslySetInnerHTML={{
            __html: props.description?.description
              ? props.description?.description.replace(/<\/p>/g, '</p><br />')
              : DEFAULT_DESCRIPTION_TEXT,
          }}
        />
        {props.learningPoints && (
          <div css={RequestDocumentOverviewWrapperStyle}>
            <h3 css={RequestDocumentOverviewHeadingStyle}>
              本資料を通して、こんなことがわかります
            </h3>
            <ul css={RequestDocumentOverviewListStyle}>
              {props.learningPoints.map((text, index) => (
                <li key={index} css={RequestDocumentOverviewStyle}>
                  {text}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div css={RequestDocumentFlowStyle}>
          <p>
            フォームに必要事項をご記入の上、送信ボタンを押してください。
            <br />
            フォーム入力後、メールにて
            {props.type === 'whitepaper' ? '資料を送付' : '動画のURLを送付'}
            いたします。
          </p>
        </div>
      </RegistrationLayout>
    </Layout>
  );
};

const RequestDocumentTitleStyle = css({
  color: colors.black,
  fontSize: 40,

  '@media(max-width: 1119px)': {
    fontSize: 28,
  },
});

const RequestDocumentDescriptionStyle = css({
  p: { fontSize: 16, lineHeight: 2, letterSpacing: 0.05 },

  '@media(max-width: 768px)': {
    br: { display: 'none' },
    p: { fontSize: 14 },
  },
});

const RequestDocumentFlowStyle = css({
  padding: 30,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  border: `2px solid ${colors.disabled}`,
  borderRadius: 16,

  p: { fontSize: 16, fontWeight: 500 },

  '@media(max-width: 768px)': {
    br: { display: 'none' },
    p: { fontSize: 14 },
  },
});

const RequestDocumentOverviewWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
  lineHeight: 1,
});

const RequestDocumentOverviewHeadingStyle = css({
  fontSize: 28,
  fontWeight: 700,
  lineHeight: 1,
  color: colors.primary,

  '@media(max-width: 768px)': {
    fontSize: 24,
  },
});

const RequestDocumentOverviewListStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  paddingLeft: 22,

  '@media(max-width: 768px)': {
    gap: 12,
  },
});

const RequestDocumentOverviewStyle = css({
  fontSize: 16,

  '@media(max-width: 768px)': {
    fontSize: 14,
  },

  '&::marker': {
    color: colors.primary,
  },
});

export default DocumentsTemplate;
